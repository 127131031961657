.App {
  text-align: center;
  min-height: 100vh;
  min-width: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.div #root {
  width: 100%;
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: "Helvetica-UltraLight";
  src: url("../public/fonts/HUltraLight.ttf");
}
@font-face {
  font-family: "Helvetica-Light";
  src: url("../public/fonts/HLight.ttf");
}
@font-face {
  font-family: "Helvetica-Regular";
  src: url("../public/fonts/HRegular.ttf");
}
@font-face {
  font-family: "Helvetica-Bold";
  src: url("../public/fonts/HBold.ttf");
}
